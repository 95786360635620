export const ADMIN_BANNER_FAIL = "ADMIN_BANNER_FAIL";
export const ADMIN_BANNER_UPDATE_SUCCESS = "ADMIN_BANNER_UPDATE_SUCCESS";
export const ADMIN_BANNER_UPDATE_RESET = "ADMIN_BANNER_UPDATE_RESET";

export const ADMIN_BANNER_SUCCESS = "ADMIN_BANNER_SUCCESS";
export const ADMIN_BANNER_GET_SUCCESS = "ADMIN_BANNER_GET_SUCCESS";
export const ADMIN_BANNER_REQUEST = "ADMIN_BANNER_REQUEST";
export const ADMIN_BANNER_RESET = "ADMIN_BANNER_RESET";


