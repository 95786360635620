const menuItems= [
  {
    title: 'Products',
    path: '/',
    cName: 'nav-text'
  },
  // {
  //   title: 'Products',
  //   path: '/products',
  //   cName: 'nav-text'
  // },
  {
    title: 'Contact Us',
    path: '/contact',
    cName: 'nav-text'
  },

  {
    title: 'About Us',
    path: '/about',
    cName: 'nav-text'
  }
];
  
  export default menuItems;
  